<template>
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col xxl="9" xl="9" lg="8" md="8" sm="8" cols="12">
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col xxl="3" xl="3" lg="4" md="4" sm="4" cols="12">
          <v-btn block color="amber darken-4" dark @click="dialogSave()">
            <v-icon class="mr-2">mdi-plus</v-icon> Adicionar
          </v-btn>
        </v-col>
      </v-row>      

      <v-row justify="end">
        <v-col xxl="12" xl="12" lg="6" md="6" sm="6" cols="12">
          <v-menu offset-y left close-on-click :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn block color="deep-purple darken-4" dark v-bind="attrs" v-on="on">
                <v-icon class="mr-2">mdi-magnify-plus-outline</v-icon>Filtrar
              </v-btn>
            </template>
            <v-card width="400px" class="pa-5">
              <b>Setor</b>
              <v-select
                :items="sector"
                placeholder="Selecione"
                multiple
                clearable
                item-text="name"
                v-model="filter.sector"
                item-value="name"
                @change="filtered()"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.name }}</span>
                  <sup v-if="index === 1" class="grey--text text-caption">
                    (+{{ filter.sector.length - 1 }})
                  </sup>
                </template>
              </v-select>
              <b>Classificação de Risco</b>
              <v-select
                :items="risks"
                placeholder="Selecione"
                multiple
                clearable
                item-text="text"
                v-model="filter.risk"
                item-value="value"
                @change="filtered()"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.text }}</span>
                  <sup v-if="index === 1" class="grey--text text-caption">
                    (+{{ filter.risk.length - 1 }})
                  </sup>
                </template>
              </v-select>
              <b>Probabilidade</b>
              <v-select
                :items="probability"
                placeholder="Selecione"
                multiple
                clearable
                item-text="text"
                v-model="filter.probability"
                item-value="value"
                @change="filtered()"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.text }}</span>
                  <sup v-if="index === 1" class="grey--text text-caption">
                    (+{{ filter.probability.length - 1 }})
                  </sup>
                </template>
              </v-select>
              <b>Impacto</b>
              <v-select
                :items="impact"
                placeholder="Selecione"
                multiple
                clearable
                item-text="text"
                v-model="filter.impact"
                item-value="value"
                @change="filtered()"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.text }}</span>
                  <sup v-if="index === 1" class="grey--text text-caption">
                    (+{{ filter.impact.length - 1 }})
                  </sup>
                </template>
              </v-select>
              <b>Responsável</b>
              <v-select
                :items="people"
                placeholder="Selecione"
                multiple
                clearable
                item-text="name"
                v-model="filter.people"
                item-value="id"
                @change="filtered()"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.name }}</span>
                  <sup v-if="index === 1" class="grey--text text-caption">
                    (+{{ filter.people.length - 1 }})
                  </sup>
                </template>
              </v-select>
              <b>Status</b>
              <v-select
                :items="status"
                placeholder="Selecione"
                multiple
                clearable
                item-text="name"
                v-model="filter.status"
                @change="filtered()"
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0">{{ item.name }}</span>
                  <sup v-if="index === 1" class="grey--text text-caption">
                    (+{{ filter.status.length - 1 }})
                  </sup>
                </template>
              </v-select>
            </v-card>            
          </v-menu>
        </v-col> 

        <!-- <v-col md="4" sm="4" cols="4"> -->
        <v-col xxl="12" xl="12" lg="6" md="6" sm="6" cols="12">
          <v-btn
            block
            color="success"
            dark
            @click="csvExport(filterObjects)"
          >
            <v-icon class="mr-2">mdi-export</v-icon>exportar CSV
          </v-btn>
        </v-col>
      </v-row>      
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table 
        :headers="header" 
        :items="filterObjects" 
        :search="search" 
        dense 
        locale="pt-BR"
        :items-per-page="10"        
      >
        <template v-slot:item.idProbability="{ item }">  
          <v-chip label small text-color="white" :color="getProbabilityColor(item.idProbability)">
            {{ getProbability(item.idProbability) }}
          </v-chip>        
        </template>    
        <template v-slot:item.idImpact="{ item }">
          <v-chip label small text-color="white" :color="getImpactColor(item.idImpact)">
            {{ getImpact(item.idImpact) }}
          </v-chip>
        </template>    
        <template v-slot:item.risk="{ item }">
          <v-chip label small text-color="white" :color="getRiskColor(item.risk)">
            {{ item.risk | formatRisk }}
          </v-chip>
        </template>    
        <template v-slot:item.deadline="{ item }">
          {{ item.deadline | formatDate }}
        </template>    
        <template v-slot:item.status="{ item }" >
          <v-chip label small text-color="white" :color="getStatusColor(item.status)">
            {{ item.status }}
          </v-chip>
        </template>    
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>    

    <TheDialog v-model="dialog" :title="title" :icon="pageIcon" width="1200px">
      <template>
        <v-form v-model="valid" ref="form1">
          <v-row>
            <v-col>
              <b>Vulnerabilidade<sup>*</sup></b>
              <v-select
                :items="vulnerability"
                placeholder="Selecione..."
                item-text="name"
                item-value="id"
                v-model="object.idVulnerability"
                dense
                hide-details
                outlined
                class="mt-2"
                clearable
                :rules="requiredRule"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b>Responsável<sup>*</sup></b>
              <v-select
                :items="people"
                placeholder="Selecione..."
                item-text="name"
                item-value="id"
                v-model="object.idPeople"
                dense
                hide-details
                outlined
                class="mt-2"
                clearable
                :rules="requiredRule"
              ></v-select>
            </v-col>
            <v-col>
              <b>Prazo<sup>*</sup></b>

              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    placeholder="Data"
                    persistent-hint
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date = parseDate(dateFormatted)"
                    v-on="on"
                    dense
                    hide-details
                    outlined
                    class="mt-2"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :rules="requiredRule"
                  v-model="date"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <b>Status<sup>*</sup></b>
              <v-select
                :items="status"
                placeholder="Selecione..."
                item-text="name"
                item-value="name"
                v-model="object.status"
                dense
                hide-details
                outlined
                class="mt-2"
                clearable
                :rules="requiredRule"
              ></v-select>
            </v-col>
          </v-row>
          <b>Ação</b>
          <VueEditor
            v-model="object.content"
            :editor-toolbar="customToolbar"
            class="mt-2"
          />
        </v-form>
      </template>
      <template v-slot:actions="">
        <v-btn
          small
          depressed
          color="primary"
          class="mt-2"
          dark
          @click="resolveForm()"
        >
          <v-icon small class="mr-2">mdi-plus</v-icon>
          <span v-if="!edit">Adicionar</span>
          <span v-if="edit">Atualizar </span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>
    </TheDialog>
  </v-container>
</template>

<script>
import axios from "axios"
import { baseApiUrl, showError } from "@/global"
import TheTitle from "@/components/TheTitle"
import TheDialog from "@/components/TheDialog"
import TheToolTip from "@/components/TheToolTip"
import { VueEditor } from "vue2-editor"

export default {
  name: "Dados",
  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
    VueEditor,
  },
  data: () => ({
    baseRoute: "action",
    title: "Matriz de Riscos",
    pageIcon: "mdi-alert-circle-outline",

    object: {},
    objects: [],
    filter: {},
    filterObjects: [],
    vulnerability: [],
    people: [],
    sector: [],
    
    dialog: false,
    valid: false,
    error: false,
    edit: false,
    search: null,
    dateFormatted: null,
    menu1: false,
    date: null,

    header: [
      { text: "Vulnerabilidades", value: "vulnerabilityName" },
      { text: "Setor", value: "sector", align: "center" },
      { text: "Probalidade", value: "idProbability", align: "center" },
      { text: "Impacto", value: "idImpact", align: "center" },
      { text: "Classificação do risco", value: "risk", align: "center" },
      { text: "Responsável", value: "peopleName" },
      { text: "Prazos", value: "deadline" },
      { text: "Status", value: "status", align: "center" },
      { text: "Ações", value: "action", align: "right", width: "8%" },
    ],

    status: [
      { name: "Não Iniciado" },
      { name: "Em Andamento" },
      { name: "Finalizado" },
    ],

    probability: [
      { text: "Quase certo", value: 1 },
      { text: "Alta", value: 0.8 },
      { text: "Média", value: 0.6 },
      { text: "Baixa", value: 0.4 },
      { text: "Rara", value: 0.2 },
    ],

    impact: [
      { text: "Gravíssimo", value: 0.8 },
      { text: "Grave", value: 0.6 },
      { text: "Médio", value: 0.4 },
      { text: "Leve", value: 0.2 },
      { text: "Sem Impacto", value: 0.05 },
    ],

    risks: [
      { text: "Risco Baixo", value: 0.1 },
      { text: "Risco Médio", value: 0.2 },
      { text: "Risco Alto", value: 1 },
    ],
    
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    
  }),

  watch: {
    date() {
      this.object.deadline = this.date
      this.dateFormatted = this.formatDate(this.date)
    },
  },

  methods: {

    loadData() {
      const url = `${baseApiUrl}/${this.baseRoute}`
      this.$store.dispatch("setLoadingInfo", true)
      axios
        .get(url)
        .then((res) => {
          this.objects = res.data
          this.filterObjects = res.data
          this.$store.dispatch("setLoadingInfo", false)
        })
        .catch(showError)
    },

    loadSector() {
      const url = `${baseApiUrl}/sector`
      axios
        .get(url)
        .then((res) => {
          this.sector = res.data
        })
        .catch(showError)
    },

    loadVulnerability() {
      const url = `${baseApiUrl}/vulnerability`
      axios
        .get(url)
        .then((res) => {
          this.vulnerability = res.data
        })
        .catch(showError)
    },

    loadPerson() {
      const url = `${baseApiUrl}/people`
      axios
        .get(url)
        .then((res) => {
          this.people = res.data
        })
        .catch(showError)
    },

    csvExport(arrData) {
      let formatedArray = []
      arrData.forEach((el, i) => {
        formatedArray[i] = {
          nome: null,
          descricao: null,
          setores: null,
          planoDeAcao: null,
          probabilidade: null,
          impacto: null,
          risco: null,
          inicio: null,
          prazo: null,
          status: null,
        }
        formatedArray[i].nome = el.vulnerabilityName
        if (el.content) {
          formatedArray[i].descricao = el.content.replaceAll(
            /<\/?[^>]+(>|$)/g,
            ""
          )
        }
        formatedArray[i].setores = el.sector
        let string = el.planList.map((element, i) => {
          if (i < el.planList.length - 1) {
            return element.name + ", "
          }
          1
          return element.name
        })
        formatedArray[i].planoDeAcao = string
        let probability = this.getProbability(el.idProbability)
        formatedArray[i].probabilidade = probability
        let impact = this.getImpact(el.idImpact)
        formatedArray[i].impacto = impact
        let risk = this.getRisk(el.risk)
        formatedArray[i].risco = risk
        let createdDate = this.formatDay(el.createdAt)
        formatedArray[i].inicio = createdDate
        let deadline = this.formatDay(el.deadline)
        formatedArray[i].prazo = deadline
        formatedArray[i].status = el.status
      })

      let csvContent = "data:text/csv;charset=utf-8,\uFEFF"
      csvContent += [
        Object.keys(formatedArray[0]).join(";"),
        ...formatedArray.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "")

      const data = encodeURI(csvContent)
      const link = document.createElement("a")
      link.setAttribute("href", data)
      link.setAttribute("download", "export.csv")
      link.click()
    },

    dialogSave() {
      this.object = {}
      this.date = null
      this.edit = false
      this.dialog = true
    },

    dialogUpdate(item) {
      this.object = item
      this.date = this.object.deadline
      this.edit = true
      this.dialog = true
    },

    resolveForm() {
      this.$refs.form1.validate()
      if (!this.valid) {
        this.$store.dispatch("setErrorInfo", {
          open: true,
          text:
            "Os campos não foram preenchidos corretamente, você pode verificar?",
          button: "Ok!",
        })
        return
      }
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },

    save() {
      this.error = false
      const url = `${baseApiUrl}/${this.baseRoute}`
      const objects = this.object
      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },

    update() {
      this.error = false
      const id = this.object.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object

      delete objects.vulnerabilityName
      delete objects.risk
      delete objects.peopleName
      delete objects.id
      delete objects.idProbability
      delete objects.idImpact
      delete objects.planList
      delete objects.sector
      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt

      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    
    parseDate(date) {
      if (!date) return null
      const [month, day, year] = date.substring(0, 11).split("/")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.substring(0, 10).split("-")
      return `${day}/${month}/${year}`
    },

    getRiskColor(risk) {
      if (risk <= 0.12) {
        return "#43A047"
      }
      if (risk > 0.12 && risk <= 0.32) {
        return "#FBC02D"
      }
      return "#F44336"
    },

    getStatusColor(status) {
      if (status == "Em Andamento") {
        return "#FBC02D"
      }
      else if (status == "Finalizado") {
        return "#4CAF50"
      }
      else if (status == "Não Iniciado") {
        return "#757575" 
      }
    },

    getProbability(id) {
      for (let i = 0; i < this.probability.length; i++) {
        const element = this.probability[i]
        if (element.value === id) {
          return element.text
        }
      }
      return ""
    },

    getImpactColor(id) {
      const prob = this.getImpact(id)
      if (prob === "Gravíssimo") {
        return "#B71C1C"
      }
      if (prob === "Grave") {
        return "#EF5350"
      }
      if (prob === "Médio") {
        return "#FFB300"
      }
      if (prob === "Leve") {
        return "#7CB342"
      }
      if (prob === "Sem Impacto") {
        return "#43A047"
      }
      return ""
    },

    getImpact(id) {
      for (let i = 0; i < this.impact.length; i++) {
        const element = this.impact[i]
        if (element.value === id) {
          return element.text
        }
      }
      return ""
    },

    getRisk(risk) {
      if (!risk) {
        return ""
      }
      if (risk <= 0.12) {
        return "Risco Baixo"
      }
      if (risk > 0.12 && risk <= 0.32) {
        return "Risco Médio"
      }
      return "Risco Alto"
    },

    getProbabilityColor(id) {
      const prob = this.getProbability(id)
      if (prob === "Quase certo") {
        return "#B71C1C"
      }
      if (prob === "Alta") {
        return "#EF5350"
      }
      if (prob === "Média") {
        return "#FFB300"
      }
      if (prob === "Baixa") {
        return "#7CB342"
      }
      if (prob === "Rara") {
        return "#43A047"
      }
      return ""
    },    

    filtered() {
      const list = []
      for (let i = 0; i < this.objects.length; i++) {
        const element = this.objects[i]
        if (this.filterCondition(element)) {
          list.push(element)
        }
      }
      if (
        list.length === 0 &&
        (!this.filter.risk ||
          (this.filter.risk && this.filter.risk.length === 0)) &&
        (!this.filter.probability ||
          (this.filter.probability && this.filter.probability.length === 0)) &&
        (!this.filter.impact ||
          (this.filter.impact && this.filter.impact.length === 0)) &&
        (!this.filter.status ||
          (this.filter.status && this.filter.status.length === 0)) &&
        (!this.filter.sector ||
          (this.filter.sector && this.filter.sector.length === 0)) &&
        (!this.filter.people ||
          (this.filter.people && this.filter.people.length === 0))
      ) {
        this.filterObjects = this.objects
      }
      this.filterObjects = list
    },

    filterCondition(element) {
      return (
        this.riskFilter(element) &&
        this.probabilityFilter(element) &&
        this.impactFilter(element) &&
        this.peopleFilter(element) &&
        this.statusFilter(element) &&
        this.sectorFilter(element)
      )
    },

    riskFilter(element) {
      if (this.filter.risk && this.filter.risk.length > 0) {
        for (let j = 0; j < this.filter.risk.length; j++) {
          const risk = this.filter.risk[j]
          if (
            this.$options.filters.formatRisk(element.risk) ===
            this.$options.filters.formatRisk(risk)
          ) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    },
    
    probabilityFilter(element) {
      if (this.filter.probability && this.filter.probability.length > 0) {
        for (let j = 0; j < this.filter.probability.length; j++) {
          const probability = this.filter.probability[j]
          if (
            this.getProbability(element.idProbability) ===
            this.getProbability(probability)
          ) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    },
    impactFilter(element) {
      if (this.filter.impact && this.filter.impact.length > 0) {
        for (let j = 0; j < this.filter.impact.length; j++) {
          const impact = this.filter.impact[j]
          if (this.getImpact(element.idImpact) === this.getImpact(impact)) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    },
    peopleFilter(element) {
      if (this.filter.people && this.filter.people.length > 0) {
        for (let j = 0; j < this.filter.people.length; j++) {
          const people = this.filter.people[j]
          if (element.idPeople === people) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    },
    statusFilter(element) {
      if (this.filter.status && this.filter.status.length > 0) {
        for (let j = 0; j < this.filter.status.length; j++) {
          const status = this.filter.status[j]
          if (element.status === status) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    },
    sectorFilter(element) {
      if (this.filter.sector && this.filter.sector.length > 0) {
        for (let j = 0; j < this.filter.sector.length; j++) {
          const sector = this.filter.sector[j]
          if (element.sector.includes(sector)) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    },
  },
  mounted() {
    this.loadData()
    this.loadSector()
    this.loadVulnerability()
    this.loadPerson()
  },
}
</script>

<style>
.full-height {
  height: 80vh;
}
</style>
